// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getMessaging } from 'firebase/messaging';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"; // Cambiado a initializeAppCheck
import { getAnalytics, logEvent } from "firebase/analytics"; 

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Inicializa la aplicación de Firebase
const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeiR2YqAAAAAClxtL7bfbvFYHJW824OVMyFyNcx'),
  isTokenAutoRefreshEnabled: true  // Activa el refresco automático del token
});


// Inicializar FCM

if (window.location.hostname === 'localhost') {
  // Habilitar modo debug en entornos locales
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  console.log('App Check debug mode enabled');
}

// Inicializar Firebase Analytics
const analytics = getAnalytics(app);

// Registrar eventos personalizados si es necesario
logEvent(analytics, "notification_received");


// Exporta la autenticación y la base de datos
// Messaging service
export const auth = getAuth(app);
export const database = getDatabase(app);
export const messaging = getMessaging(app);
export const firebaseAnalytics = analytics; // Exporta analytics