import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PrivacyPolicy = () => {
  return (
    <div style={{ margin: '20px', padding: '20px' }}>
      <Typography variant="h3" gutterBottom align="center">
        Política de Privacidad
      </Typography>
      <Typography variant="subtitle1" gutterBottom align="center">
        Fecha de última actualización: [02/01/2024]
      </Typography>

      {/* Introducción */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="intro-content"
          id="intro-header"
        >
          <Typography variant="h6">Introducción</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Esta Política de Privacidad explica cómo recopilamos, usamos, protegemos y compartimos la
            información obtenida de los usuarios a través de nuestra aplicación. Al utilizar esta
            aplicación, usted acepta las prácticas descritas en este documento.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Información que obtiene la aplicación */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="info-content"
          id="info-header"
        >
          <Typography variant="h6">Información que obtiene la aplicación y cómo se utiliza</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle1" gutterBottom>
            Información proporcionada por el usuario
          </Typography>
          <Typography variant="body1">
            La aplicación no requiere registro de cuenta. Sin embargo, es necesario que el usuario
            tenga una cuenta activa en Google Play Store para descargar la aplicación.
          </Typography>
          <Typography variant="subtitle1" gutterBottom style={{ marginTop: '10px' }}>
            Información obtenida automáticamente
          </Typography>
          <Typography variant="body1">
            Podemos recopilar automáticamente datos técnicos relacionados con el dispositivo y el uso
            de la aplicación, tales como:
            <ul>
              <li>Tipo de dispositivo móvil.</li>
              <li>País del usuario.</li>
              <li>Dirección IP.</li>
              <li>Sistema operativo del dispositivo móvil.</li>
              <li>Información sobre el uso de la aplicación.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Ubicación precisa y en tiempo real */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="location-content"
          id="location-header"
        >
          <Typography variant="h6">Ubicación precisa y en tiempo real</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            La aplicación <b>no recopila información precisa ni en tiempo real de la ubicación del
            dispositivo</b> en ningún momento.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Acceso de terceros a la información */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="third-party-content"
          id="third-party-header"
        >
          <Typography variant="h6">Acceso de terceros a la información</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            La información obtenida por la aplicación puede ser compartida con terceros en los
            siguientes casos:
            <ol>
              <li>
                <b>Cumplimiento legal</b>: Cuando sea requerido por ley o en respuesta a una solicitud
                gubernamental válida.
              </li>
              <li>
                <b>Protección de derechos y seguridad</b>: Para proteger nuestros derechos, la
                seguridad de nuestros usuarios o de terceros, o para investigar fraudes.
              </li>
              <li>
                <b>Proveedores de servicios</b>: Con proveedores que trabajan en nuestro nombre y que
                han aceptado regirse por esta Política de Privacidad.
              </li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Recolección automática de datos */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="auto-data-content"
          id="auto-data-header"
        >
          <Typography variant="h6">Recolección automática de datos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Además de lo indicado previamente, los datos técnicos pueden ser recolectados
            automáticamente para garantizar el correcto funcionamiento y mejorar la experiencia del
            usuario en la aplicación.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Retención de datos */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="data-retention-content"
          id="data-retention-header"
        >
          <Typography variant="h6">Política de retención de datos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Conservamos los datos proporcionados y recopilados automáticamente mientras sean
            necesarios para operar la aplicación y ofrecer los servicios asociados. Cuando los datos
            ya no sean necesarios, los eliminaremos de forma segura, salvo que la ley requiera lo
            contrario.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Medidas de seguridad */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="security-content"
          id="security-header"
        >
          <Typography variant="h6">Medidas de seguridad</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Hemos implementado medidas técnicas y organizativas para proteger la confidencialidad de
            su información, tales como:
            <ul>
              <li>Restricción de acceso a empleados y contratistas autorizados.</li>
              <li>Mecanismos de cifrado para la transmisión y almacenamiento de datos.</li>
            </ul>
            Aunque nos esforzamos por garantizar la seguridad, ningún sistema es completamente
            infalible.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Cambios en la Política */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="changes-content"
          id="changes-header"
        >
          <Typography variant="h6">Cambios en la Política de Privacidad</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Nos reservamos el derecho de actualizar esta Política de Privacidad periódicamente. Las
            actualizaciones se publicarán en esta página, indicando la fecha de la última
            modificación.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Contacto */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="contact-content"
          id="contact-header"
        >
          <Typography variant="h6">Contacto</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Si tiene preguntas o inquietudes sobre esta Política de Privacidad, puede contactarnos
            en:
            <ul>
              <li>Correo electrónico: [parquetecnologicoja@gmail.com]</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PrivacyPolicy;